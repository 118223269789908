import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import '../css/DonationForm.css';

export default function DonationForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0); // Donation amount set by user

  // Handle Stripe payment submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://caseinformation.andrewbrowne.org/", // URL to redirect after success
      },
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <div className="donation-form-container">
      <form id="payment-form" onSubmit={handleSubmit}>
        <label htmlFor="donation-amount">Enter Donation Amount ($):</label>
        <input
          type="number"
          id="donation-amount"
          value={donationAmount}
          onChange={(e) => setDonationAmount(e.target.value)}
          min="1"
          placeholder="10"
          required
        />
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Donate"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
