import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import DonationForm from "./DonationForm";
import '../css/DonationPage.css';
import NavBar from '../landingpagecomponents/Navbar';
import Footer from '../landingpagecomponents/Footer';

const stripePromise = loadStripe('pk_live_51OGPqzFg0IM8tPHkISRnJ4WyaCkWlM3A775ipg5gqUxcQZ05tN8Bh7FJLLDRf3pqf9nk2TFGNJBD18m5qPbmcY7x00eA46JtNp');

export default function DonationPage() {
  const [clientSecret, setClientSecret] = useState('');
  const [donationAmount, setDonationAmount] = useState(2);
  // Options for Stripe Elements
  const options = {
    clientSecret,
    appearance: { theme: 'stripe' },
  };
  // Fetch clientSecret from the backend when donation amount is set
  useEffect(() => {
    // Dummy data for testing
    const userId = "133"; // Hardcoded user ID
    const items = [{ id: "dummyItemId", type: "dummyType" }]; // Hardcoded items array
    const dummyTotalCost = 10.0; // Example total cost
    const dummyDiscountCode = "DUMMYCODE";
    const dummySubscriptionType = "basic";
    const dummyDiscountApplied = true;
    
    if (userId && dummyTotalCost > 0) { // Ensure dummyTotalCost is valid
      fetch("https://api.andrewslearning.com/api/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          totalCost: dummyTotalCost, 
          userId, 
          items, 
          discountCode: dummyDiscountCode, 
          subscriptionType: dummySubscriptionType, 
          discountApplied: dummyDiscountApplied 
        }),
      })
      .then(res => res.json())
      .then(data => {
        console.log('Payment Intent created:', data);
        setClientSecret(data.clientSecret); // Set clientSecret once API response is received
      })
      .catch(error => console.error('Error creating payment intent:', error));
    } else {
      console.error('User ID not found in local storage or invalid totalCost.');
    }
  }, []); // Empty dependency array to run once on mount
  

  return (
    <div className="donation-page">
        <NavBar />
      <h1>Support Our Mission</h1>
      <p>Your contributions directly support ongoing projects and legal aid services.</p>

      {/* Render Stripe Elements only if clientSecret is available */}
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <DonationForm />
        </Elements>
      )}
      
    </div>
  );
}
