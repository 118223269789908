import React from 'react';
import '../css/navbar2.css';
import { Link } from 'react-router-dom';

const NavBar2 = () => {
  return (
      <nav className="header-nav2">
        <ul className="nav-list2">
          <li><Link className="header-nav-item2" to="/">Home</Link></li>
          <li><Link className="header-nav-item2" to="/case">Featured Case</Link></li>
          <li><Link className="header-nav-item2" to="/donation">Donation</Link></li>
        </ul>
      </nav>
  );
};

export default NavBar2;
